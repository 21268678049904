// -------------------------- Bootstrap extra small columns ------------------
@media (max-width: 480px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: 100%;
    float: none;
    margin-bottom: 10px;
    padding-top: 5px;
  }
}