@import "partials/variables.scss";

@import "bootstrap";

@import "partials/bootstrapDataTable";

@import "partials/bootstapExtraSmall";

@import "bootstrapOverides";

@import "slick";

@import "slick-theme";

@import "font-awesome";

// Layout ----------------------------------------------------------

body {
  background-color: $background-color;
  font-family: "Nunito", Tahoma, arial, sans-serif;
  color: $color;
}

.header {
  background: url('/images/header.png') repeat-x 50% 100%;
  background-color: $blue;
  color: white;
  width:100%;
  padding: 20px 0;

  div.logo {
    padding-top:20px;

    @media screen and (max-width:480px) {
      text-align: center;
    }
  }

  .phone {
    text-align: right;
    padding-top: 20px;

    .big {
      font-size: 2.0rem;
      line-height: 1.2;
      display:block;

      @media screen and (max-width: 640px) {
        font-size: 2.2rem;
        line-height: 1.4;
      }

      @media screen and (max-width: 320px) {
        font-size: 1.6rem;
        line-height: 1.4;
      }

    }

    @media screen and (max-width:480px) {
      text-align: center;
    }

  }

}

.footer {
  background-color: #000000;
  color: #FFFFFF;
  padding: 60px 0;
  width:100%;
  margin-top:40px;

  a {
    color: $blue;
  }
}

.section {
  padding: 20px 0 0 0;
}

// Section Styles ----------------------------------------------------------
.slideSection {
}

.contentSection {
  background: url('/images/page_bg.png') no-repeat bottom right;

}

.partnerSection {

}

.sidebar  ul.nav a
{
  color: white;
  background-color: $blue-light;
  font-weight: bold;
  font-size: 1.8rem;
  text-transform: uppercase;

  &:before {
    content: "> ";
  }

  &:hover {
    background-color:  $blue;
  }
}



.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: $blue;
  background-color: white;
  border: solid 2px $blue-light;
   &:before {
     content: "";
   }
}

// Tags ----------------------------------------------------------

a {
  color: $links;
}

a:hover {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

address {
  padding-top: 15px;
}



// Styles ----------------------------------------------------------
.partner img {
  //border: 4px solid $partner-border;
  max-height: 110px;
}

a.partner {
  margin-bottom: 20px;
  width: 100%;
  height: 100px;
  display: table-cell;
  vertical-align: middle;
}

a.partner:hover img {
  -moz-transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  opacity: 0.8;
}

.partner-small {
  display: block;
  text-align: center;
}

.partner-page {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  height:150px;
}

.partner-small img, .partner-page img {
  max-height: 100px;
  margin: 20px auto 0 auto;
}

.padded {
  padding: 10px;
}

.noTopMargin {
  margin-top:0;
}

.smallTopMargin {
  margin-top:5px;
}

.resources {
  h3 {
    color: #0662a3;
  }
}

// ------------------------ Widgets -----------------------------------

.form-hilight div.form-group:hover {
  background-color: #fbfbfb;
}
div#dropzone.dropzone.dz-clickable {
  border: 2px dashed blue;
  margin: 10px 0;
}

.table > tbody > tr > td.vertical-middle {
  vertical-align: middle;
}

.partner-text {
  min-height: 40px;
}

.carousel-indicators {
  bottom: 0;
  margin-bottom: 5px;
}

.carousel-caption {
  background-color:  rgba(0,0,0,0.5);
  left:0;
  right:0;
  bottom: 0;
  padding-top: 0;

  h2 {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 2.0rem;
  }
}

.sting {
  background-color: $blue-light;

  h3 {
    margin-top:5px;
  }

  .action {
    color: white;
  }
}

.famous {
  min-height: 400px;
}

.wrap-left {
  float: left !important;
  margin-right:10px;
  margin-bottom: 10px;
}

.wrap-right {
  float: right !important;
  margin-left:10px;
  margin-bottom: 10px;
}