$background-color: #FFF;
$color: #333;

$blue: #0662a3;
$blue-light: #3f7ead;

$partner-border: #444;

$header-link: #000;
$header-link-hover:#444;

$footer-link: #000;

$links: #000;
$links-hover: $color;

$fa-font-path:   "/fonts/fontawesome";