//Navbar

.navbar-nav > li > a {padding-top:10px !important; padding-bottom:10px !important;}
.navbar {min-height:40px !important; margin-bottom: 0;}

.navbar-default {
  background-color: $blue;
  border:none;
//  border-top: 1px solid $green;
//  border-bottom: 9px solid $gold;
  border-radius: 0;
  padding: 4px 0 0;
  font-size: 1.5rem;
  text-transform: uppercase;

  @media screen and (max-width: 1024px) {
    font-size: 1.2rem;
    padding: 3px 0 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
    padding: 10px 0 0;
  }

  @media screen and (max-width: 640px) {
    font-size: 1.5rem;
  }
}

.nav > li > a {
  @media screen and (max-width: 768px) {
    padding: 5px 10px;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

.navbar-brand {
  padding-top: 5px;
  height:40px;
}

.navbar-default .navbar-nav > li > a {
  color: #CCC;
  margin-right: 2px;

  @media screen and (max-width: 640px) {
    text-align: center;
  }
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: $blue;
  background-color: #FFF;
  border-radius: 4px 4px 0 0;
  margin-right: 2px;

  @media screen and (max-width: 767px) {
    border-radius: 0 0 0 0;
    margin-right: 0;
  }
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  background-color: #FFF;
  border-radius: 4px 4px 0 0;

  @media screen and (max-width: 767px) {
    border-radius: 0 0 0 0;
  }
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  background-color: #FFF;
  border-radius: 4px 4px 0 0;

  @media screen and (max-width: 767px) {
    border-radius: 0 0 0 0;
  }
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  color: #000;
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
  background-color: #FFF;
  color: $blue;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: #FFF;
  padding-left: 0;
}
//end nav